<script setup lang="ts">
import { uiLanguagesArray } from "~/types/general";
import { useI18n } from "vue-i18n";

const { t, locale } = useI18n();
const { signOut } = useAuth();

const colorModeOptions = () => [
  { value: "system", name: t("components.navbar.colorMode.system") },
  { value: "dark", name: t("components.navbar.colorMode.dark") },
  { value: "light", name: t("components.navbar.colorMode.light") },
];

watch(
  () => useColorMode().preference,
  (colorMode) => {
    localStorage.setItem("colorMode", colorMode);
  },
);
watch(locale, (lang) => localStorage.setItem("lang", lang));

// TODO activate when ready
// const showSettingsOverlay = useShowSettingsOverlay();
</script>

<template>
  <nav
    class="fixed top-0 flex w-full items-center justify-between bg-orange-300 p-1 dark:bg-gray-800"
  >
    <div class="flex items-center">
      <NuxtLink
        to="/"
        class="px-4 text-2xl font-bold text-black dark:text-white"
      >
        <span class="hidden md:inline-flex">
          <img class="my-auto h-6" src="/favicon.ico" alt="Logo" />
          &nbsp;{{ t("components.navbar.companyName") }}
        </span>
        <span class="inline-flex md:hidden">
          <img class="my-auto h-6" src="/favicon.ico" alt="Logo" />
          &nbsp;O360.io
        </span>
      </NuxtLink>
    </div>
    <div class="flex h-8 items-center">
      <slot>
        <NuxtLink
          v-for="(tool, toolName) in navbarTools"
          :to="tool.path"
          class="mx-2 h-full"
        >
          <UButton color="gray" class="px-8">
            {{ t(`tools.${toolName}`) }}
          </UButton>
        </NuxtLink>
      </slot>
    </div>
    <div class="hidden h-8 items-end whitespace-nowrap md:flex">
      <UPopover>
        <UButton color="black" variant="ghost" icon="i-heroicons-cog">
          {{ t("components.settings.settings") }}
        </UButton>
        <template #panel>
          <div class="grid w-48 gap-2 p-2">
            <!-- TODO activate when ready -->
            <!--
            <UButton
              icon="i-heroicons-envelope"
              :label="t('components.settings.notifications.notifications')"
              color="black"
              variant="outline"
              block
              @click="showSettingsOverlay = true"
            />
            -->
            <USelect
              :options="colorModeOptions()"
              option-attribute="name"
              v-model="$colorMode.preference"
            />
            <USelect
              :options="uiLanguagesArray()"
              option-attribute="name"
              v-model="$i18n.locale"
            />
            <UButton
              @click="signOut()"
              color="black"
              variant="ghost"
              block
              icon="i-heroicons-arrow-right-on-rectangle"
            >
              {{ t("components.navbar.signOut") }}
            </UButton>
          </div>
        </template>
      </UPopover>
      <LazyLayoutSettings />
    </div>
  </nav>
</template>
